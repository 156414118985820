// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Header = "N_ch";
export var isCompact = "N_cj";
export var isActive = "N_ck";
export var isInverse = "N_cl";
export var bg = "N_H";
export var isHidden = "N_b6";
export var grid = "N_Q";
export var btnMenu = "N_cm";
export var logo = "N_bT";
export var logo__inner = "N_cb";
export var logo__comptact = "N_cn";
export var aniSpin = "N_c";