// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Footer = "K_b4";
export var scroll = "K_bQ";
export var fixed = "K_b5";
export var isHidden = "K_b6";
export var inner = "K_3";
export var info = "K_b7";
export var nav = "K_b8";
export var nav__list = "K_b9";
export var logo = "K_bT";
export var logo__inner = "K_cb";
export var copyright = "K_cc";
export var aniSpin = "K_c";