// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var PageTransition = "P_cp";
export var debug = "P_cq";
export var darken = "P_cr";
export var bg = "P_H";
export var shapes = "P_cs";
export var shape = "P_ct";
export var svg = "P_cv";
export var aniSpin = "P_c";