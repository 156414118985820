// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Btn = "T_cN";
export var inner = "T_3";
export var hover = "T_cP";
export var wrapper = "T_cQ";
export var text = "T_bJ";
export var bg = "T_H";
export var aniSpin = "T_c";