// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var RegisterPopup = "Q_cw";
export var scrollable = "Q_cx";
export var scrollable__inner = "Q_cy";
export var panel = "Q_cz";
export var close = "Q_cB";
export var overlay = "Q_cC";
export var bg = "Q_H";
export var inner = "Q_3";
export var container = "Q_f";
export var nav = "Q_b8";
export var item = "Q_cg";
export var link = "Q_8";
export var success__description = "Q_b3";
export var aniSpin = "Q_c";