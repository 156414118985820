// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Menu = "M_cf";
export var bg = "M_H";
export var container = "M_f";
export var nav = "M_b8";
export var item = "M_cg";
export var link = "M_8";
export var aniSpin = "M_c";